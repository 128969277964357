import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const RandomCubes = () => {
  const refContainer = useRef(null);
  const camera = useRef(new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000));
  const scene = useRef(new THREE.Scene());
  const renderer = useRef(new THREE.WebGLRenderer());
  const controls = useRef(null);
  const raycaster = useRef(new THREE.Raycaster());
  const mouse = useRef(new THREE.Vector2());
  const cubes = useRef([]);
  const targetPosition = useRef(new THREE.Vector3());
  const targetCube = useRef(null);
  const isMoving = useRef(false);

  const zoomOffset = 5; // Distance from the cube to stop

  useEffect(() => {
    camera.current.position.z = 300;
    renderer.current.setSize(window.innerWidth, window.innerHeight);
    refContainer.current.appendChild(renderer.current.domElement);
    controls.current = new OrbitControls(camera.current, renderer.current.domElement);
    controls.current.enableDamping = true;
    controls.current.dampingFactor = 0.1;

    // Create cubes and add them to the scene and cubes ref
    const cubeSize = 1;
    const maxRadius = 200;
    const geometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize);
    for (let i = 0; i < 1000; i++) {
      const material = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff, transparent: true, opacity: 0 });
      const cube = new THREE.Mesh(geometry, material);
      cube.visible = false; // Start with invisible cubes
      const radius = Math.random() * maxRadius;
      const theta = Math.random() * Math.PI * 2;
      const phi = Math.acos((Math.random() * 2) - 1);
      cube.position.setFromSphericalCoords(radius, phi, theta);
      scene.current.add(cube);
      cubes.current.push(cube);

      // Set a random timeout for each cube to start the fade-in effect
      setTimeout(() => {
        cube.visible = true;
        let fadeInDuration = 1000; // Duration of fade-in in milliseconds
        let elapsed = 0;
        const fadeInInterval = setInterval(() => {
          elapsed += 50;
          cube.material.opacity = elapsed / fadeInDuration;
          if (elapsed >= fadeInDuration) {
            clearInterval(fadeInInterval);
            cube.material.opacity = 1; // Ensure it's fully opaque
          }
        }, 50);
      }, Math.random() * 2000);
    }

    // Double click listener to detect cube selection and start camera motion
    const onDoubleClick = (event) => {
      mouse.current.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.current.y = - (event.clientY / window.innerHeight) * 2 + 1;
      raycaster.current.setFromCamera(mouse.current, camera.current);
      const intersects = raycaster.current.intersectObjects(cubes.current);

      if (intersects.length > 0) {
        targetCube.current = intersects[0].object;
        targetPosition.current.copy(intersects[0].point).add(intersects[0].face.normal.multiplyScalar(zoomOffset));
        isMoving.current = true;
      }
    };
    window.addEventListener('dblclick', onDoubleClick);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      if (isMoving.current && targetCube.current) {
        // Move the camera towards the target position
        const step = 0.02; // Animation step size
        camera.current.position.lerp(targetPosition.current, step);
        controls.current.target.lerp(targetCube.current.position, step);

        // Stop moving if we're close enough to the target position
        if (camera.current.position.distanceTo(targetPosition.current) < 0.1) {
          isMoving.current = false;
        }
      }

      controls.current.update();
      renderer.current.render(scene.current, camera.current);
    };
    animate();

    // Clean up on component unmount
    return () => {
      if (refContainer.current) {
        refContainer.current.removeChild(renderer.current.domElement);
      }
      window.removeEventListener('dblclick', onDoubleClick);
    };
  }, []);

  return <div ref={refContainer} style={{ height: '100vh' }} />;
};

export default RandomCubes;
