// App.js
import React, { useState } from 'react';
import ThreeDTree from './ThreeDTree';
import RandomCubes from './RandomCubes';

const App = () => {
  const [showThreeDTree, setShowThreeDTree] = useState(true);
  const [showRandomCubes, setShowRandomCubes] = useState(false);

  const handleTreeClick = () => {
    setShowThreeDTree(false);
    setShowRandomCubes(true);
  };

  return (
    <div>
      {showThreeDTree ? <ThreeDTree onTreeClick={handleTreeClick} /> : null}
      {showRandomCubes ? <RandomCubes /> : null}
    </div>
  );
};

export default App;
