import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const ThreeDTree = ({ onTreeClick }) => {
  const refContainer = useRef(null);
  let animationFrameId = useRef(); // Stores the requestAnimationFrame reference

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    refContainer.current.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.1;

    const cubeGeometry = new THREE.BoxGeometry();
    const cubeMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00, transparent: true });
    const cube = new THREE.Mesh(cubeGeometry, cubeMaterial);
    scene.add(cube);

    camera.position.z = 5;

    let isSpinningFast = false; // This flag controls the spinning speed

    const animate = () => {
      animationFrameId.current = requestAnimationFrame(animate);

      // Rotate the cube
      cube.rotation.x += isSpinningFast ? 0.3 : 0.01;
      cube.rotation.y += isSpinningFast ? 0.3 : 0.01;

      // Fade out the cube when isSpinningFast is true
      if (isSpinningFast && cube.material.opacity > 0) {
        cube.material.opacity -= 0.01;
      }

      // When the cube is fully transparent, call onTreeClick and cancel the animation frame
      if (isSpinningFast && cube.material.opacity <= 0) {
        scene.remove(cube);
        cancelAnimationFrame(animationFrameId.current);
        if (onTreeClick) {
          onTreeClick();
        }
      }

      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    const onCubeClick = () => {
      isSpinningFast = true; // Trigger the spinning and fading out
    };

    renderer.domElement.addEventListener('click', onCubeClick);

    // Clean up on unmount
    return () => {
      renderer.domElement.removeEventListener('click', onCubeClick);
      if (refContainer.current && refContainer.current.contains(renderer.domElement)) {
        refContainer.current.removeChild(renderer.domElement);
      }
      cancelAnimationFrame(animationFrameId.current); // Also cancel the animation frame on cleanup
    };
  }, [onTreeClick]); // Depend on onTreeClick so it gets updated if the prop changes

  return <div ref={refContainer} style={{ height: '100vh' }} />;
};

export default ThreeDTree;
